@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Roboto, system-ui, sans-serif;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@layer utilities {
  .clip-path-qr-layer {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;

    -webkit-clip-path: polygon(
      0% 0%,
      0% 100%,
      25% 100%,
      25% 25%,
      75% 25%,
      10% 75%,
      25% 75%,
      25% 100%,
      100% 100%,
      100% 0%
    );
    clip-path: polygon(
      0% 0%,
      0% 100%,
      25% 100%,
      25% 40px,
      75% 40px,
      75% 180px,
      25% 180px,
      25% 100%,
      100% 100%,
      100% 0%
    );
  }
}

/* CSS variables. */
